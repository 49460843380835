import { DiscoverySectionSeries } from './DiscoverySectionSeries';
import { DiscoverySectionItem, isSeriesSection, Story } from './sections.type';

type Props = {
  allSeries: Story[];
  section: DiscoverySectionItem;
  onSave: (section: DiscoverySectionItem) => void;
  onRemove: () => void;
};

export const DiscoverySectionRenderer: React.FC<Props> = (props) => {
  if (isSeriesSection(props.section)) {
    return (
      <DiscoverySectionSeries
        allSeries={props.allSeries}
        section={props.section}
        onSave={props.onSave}
        onRemove={props.onRemove}
      />
    );
  }

  return null;
};
