import { useMaestroToast } from '@maestro/components';
import {
  StoryFactory,
  useGetStoryFactoryQuery,
  useUpdateStoryFactoryMutation,
} from '@maestro/graphql';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { StoryFactoryForm } from './StoryFactoryForm';

export const StoryFactoryEdit: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryQuery({ id: id! });
  const { mutateAsync: updateFactory } = useUpdateStoryFactoryMutation();
  const [isSaving, setSaving] = useState(false);
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const save = async (
    factory: Pick<StoryFactory, 'prompt' | 'fields' | 'title'>,
  ) => {
    const { prompt, fields, title } = factory;

    setSaving(true);
    try {
      await updateFactory({ id: id!, input: { prompt, fields, title } });
      toast({ title: 'Factory saved', status: 'success' });
    } finally {
      setSaving(false);
    }
  };

  if (isLoading) {
    return <TableLoader />;
  } else if (!data?.storyFactory) {
    return <NotFound />;
  }

  return (
    <StoryFactoryForm
      formTitle="Editing Story Factory"
      title={data.storyFactory.title}
      prompt={data.storyFactory.prompt}
      fields={data.storyFactory.fields}
      onSave={save}
      onCancel={() => navigate(`/story-factory/${id}`)}
      isSaving={isSaving}
    />
  );
};
