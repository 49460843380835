import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { useMaestroToast } from '@maestro/components/index';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { rawDimensions } from '@maestro/styles';
import * as Sentry from '@sentry/browser';
import React, {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useContextMenu } from 'react-contexify';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  NodeChange,
  Node as ReactFlowNode,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  updateEdge,
  Connection,
} from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import { CONTEXT_MENU_ID, ContextMenu } from './components/ContextMenu';
import { PropertiesModal } from './components/PropertiesModal';
import { Sidebar } from './components/Sidebar';
import { PlayEpisodeDrawer } from './components/simulator/PlayEpisodeDrawer';
import { StudioControls } from './components/StudioControls';
import { StudioDrawer } from './components/StudioDrawer';
import { studioEdgeTypes } from './edges';
import { ArrowHead } from './edges/ArrowHead';
import { useCopyPaste } from './hooks/copyPaste/useCopyAndPaste';
import { CreateStudioNode } from './hooks/maestro.types';
import { useSuggestionsStore } from './hooks/suggestions/useSuggestionsStore';
import { useDrawerStore } from './hooks/useDrawerStore';
import { useFixNodePosition } from './hooks/useFixNodePosition';
import { useStudioFlowStore } from './hooks/useStudioFlowStore';
import { useSuggestions } from './hooks/useSuggestions';
import useUndoRedo from './hooks/useUndoRedo';
import { defaultEdgeOptions } from './hooks/utils';
import { nodeConfigList, studioNodeTypes } from './nodes';
import { StudioConnection } from './nodes/Node.types';

const proOptions = { account: 'paid-pro', hideAttribution: true };

type Props = {
  showControls?: boolean;
  onChange: () => void;
  initialNodes: ReactFlowNode<StudioNodeData>[];
  initialEdges: Edge[];
};

export const StudioFlow: React.FC<Props> = (props) => {
  const flowInstance = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState<StudioNodeData>(
    props.initialNodes,
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.initialEdges);
  const contextMenu = useContextMenu({ id: CONTEXT_MENU_ID });
  const { takeSnapshot, debouncedTakeSnapshot } = useUndoRedo();
  const reactFlowRef = useRef<HTMLDivElement>(null);
  const { setSuggestions } = useSuggestionsStore();
  const { drawerOpen, closeDrawer } = useDrawerStore();
  const {
    episodeRef,
    setDeleteNodeHandler,
    setDeleteEdgeHandler,
    selectedNode,
    selectNode,
  } = useStudioFlowStore();
  const toast = useMaestroToast();
  const onChange = useCallback(
    () => setTimeout(() => props.onChange(), 100),
    [props.onChange],
  );

  const onCreateStudioNode: CreateStudioNode = useCallback(
    ({ studioNodeData, args, autoCenter = true }) => {
      takeSnapshot();

      const item = {
        data: studioNodeData,
        type: studioNodeData.type,
        id: studioNodeData.id,
        position: flowInstance.screenToFlowPosition({ x: args.x, y: args.y }),
      };

      onNodesChange([{ type: 'add', item }]);

      const nodes = flowInstance.getNodes();
      const selectedNodes = nodes.filter((node) => node.selected);
      const unselectNodes: NodeChange[] = selectedNodes.map((node) => ({
        type: 'select',
        id: node.id,
        selected: false,
      }));

      onNodesChange([
        ...unselectNodes,
        { type: 'select', id: item.id, selected: true },
      ]);

      if (args.handleId && args.nodeId) {
        onEdgesChange([
          {
            type: 'add',
            item: {
              id: `${args.nodeId}-${item.id}`,
              source: args.nodeId,
              target: item.id,
              sourceHandle: args.handleId,
              label: args.label,
            },
          },
        ]);
      }

      /*
       * We need a set timeout because it's conflicting with the React Flow
       * events, whenever we create a new node, React Flow fires the
       * onSelectionChange event without any nodes, and we lose the selectedNodeId
       */
      setTimeout(() => selectNode(item.id), 100);
      onChange();

      if (autoCenter) {
        flowInstance.setCenter(item.position.x + 200, item.position.y, {
          zoom: flowInstance.getZoom(),
          duration: 200,
        });
      }
    },
    [
      takeSnapshot,
      onNodesChange,
      onNodesChange,
      onEdgesChange,
      selectNode,
      onChange,
      flowInstance.screenToFlowPosition,
      flowInstance.getNodes,
      flowInstance.setCenter,
      flowInstance.getZoom,
    ],
  );

  const suggestions = useSuggestions({ onCreateStudioNode });

  useEffect(() => {
    setSuggestions([]); // clear it when the flow is mounted
    closeDrawer();

    setDeleteNodeHandler((id: string) => {
      flowInstance.deleteElements({ nodes: [{ id }] });
      toast({ status: 'success', title: 'Node deleted' });
      suggestions.onNodeChange();
    });

    setDeleteEdgeHandler((id: string) => {
      delaySnapshot();
      flowInstance.deleteElements({ edges: [{ id }] });
      suggestions.onEdgeChange();
    });

    return () => {
      setDeleteNodeHandler(undefined);
      setDeleteEdgeHandler(undefined);
      selectNode(undefined);
    };
  }, []);

  const onConnect = useCallback(
    (connection: StudioConnection) => {
      takeSnapshot();
      suggestions.onEdgeChange();
      setEdges((eds) => addEdge(connection, eds));
      onChange();
    },
    [takeSnapshot, onChange, setEdges, suggestions.onEdgeChange],
  );

  const selectedStudioNode = nodes.find(
    (node) => node.id === selectedNode,
  )?.data;

  const onSelectedStudioNodeChange = useCallback(
    (reactFlowNodes: ReactFlowNode[]) => {
      if (reactFlowNodes.length !== 1) {
        selectNode(undefined);
      }
    },
    [],
  );

  const openDrawer = useCallback((_: unknown, node: ReactFlowNode) => {
    selectNode(node.id);
  }, []);

  const onStudioNodeDataChange = useCallback(
    (studioNodeData: StudioNodeData) => {
      debouncedTakeSnapshot();

      const updatedNodes = flowInstance.getNodes().map((node) => {
        return node.id === selectedNode
          ? { ...node, data: studioNodeData }
          : node;
      });

      setNodes(updatedNodes);
      onChange();
    },
    [setNodes, flowInstance.getNodes, selectedNode, onChange],
  );

  const delaySnapshot = debouncedTakeSnapshot;
  const snapshotAndChange = useCallback(() => {
    delaySnapshot();
    onChange();
  }, [delaySnapshot, onChange]);

  useCopyPaste();

  const onDrop: DragEventHandler = useCallback(
    (evt: DragEvent<HTMLDivElement>) => {
      evt.preventDefault();
      const type = evt.dataTransfer.getData('application/reactflow');
      const config = nodeConfigList.find((config) => config.type === type);

      if (!config) {
        Sentry.captureException(
          new Error(`Error creating node of type [${type}] on drag and drop`),
        );

        return;
      }

      onCreateStudioNode({
        studioNodeData: config.createNodeData(),
        args: { x: evt.clientX, y: evt.clientY },
        autoCenter: false,
      });
    },
    [onCreateStudioNode],
  );

  const onDragOver = useCallback((evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  }, []);

  const onSelectionChange = useCallback(
    ({ nodes }: { nodes: ReactFlowNode[] }) =>
      onSelectedStudioNodeChange(nodes),
    [onSelectedStudioNodeChange],
  );

  const onPaneContextMenu = useCallback(
    (event: MouseEvent) => {
      contextMenu.show({
        event,
        props: { x: event.clientX, y: event.clientY },
      });
    },
    [contextMenu.show],
  );

  const fixNodePosition = useFixNodePosition();
  const onNodesChangeInternal = useCallback(
    (changes: NodeChange[]) => {
      if (changes.length === 1 && changes[0].type === 'position') {
        onNodesChange([fixNodePosition(changes[0])]);

        return;
      }

      onNodesChange(changes);
      suggestions.onNodeChange();
    },
    [setNodes, onNodesChange, fixNodePosition],
  );

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) =>
      setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    [],
  );

  const isPropertiesMenuEnabled = useFeatureFlag(FeatureFlags.PropertiesMenu);

  return (
    <EmbeddedContainer>
      {isPropertiesMenuEnabled && (
        <PropertiesModal onStudioFlowChange={props.onChange} />
      )}
      <FlowContainer>
        <ContextMenu
          onCreateStudioNode={onCreateStudioNode}
          episodeRef={episodeRef!}
        />
        <StudioDrawer
          isOpen={
            !drawerOpen &&
            !!selectedStudioNode &&
            selectedStudioNode.type !== StudioNodeType.CharacterCreation
          }
          onCancel={() => selectNode(undefined)}
          studioNodeData={selectedStudioNode}
          onStudioNodeDataChange={onStudioNodeDataChange}
        />
        <PlayEpisodeDrawer
          isOpen={drawerOpen === 'play-episode'}
          onClose={closeDrawer}
        />
        <ReactFlow
          deleteKeyCode={['Backspace', 'Delete']}
          fitView
          minZoom={0.01}
          maxZoom={1.5}
          onChange={onChange}
          ref={reactFlowRef}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onNodeDragStart={delaySnapshot}
          onNodeDragStop={onChange}
          onSelectionDragStart={delaySnapshot}
          onSelectionDragStop={onChange}
          onNodesDelete={() => {
            snapshotAndChange();
            suggestions.onNodeChange();
          }}
          onEdgeUpdate={onEdgeUpdate}
          onEdgesDelete={() => {
            snapshotAndChange();
            suggestions.onEdgeChange();
          }}
          nodes={nodes}
          onPaneContextMenu={onPaneContextMenu}
          proOptions={proOptions}
          onNodeDoubleClick={openDrawer}
          onNodesChange={onNodesChangeInternal}
          edges={edges}
          onEdgesChange={(changes) => {
            onEdgesChange(changes);
            suggestions.onEdgeChange();
          }}
          onConnect={onConnect}
          onSelectionChange={onSelectionChange}
          defaultEdgeOptions={defaultEdgeOptions}
          nodeTypes={studioNodeTypes}
          edgeTypes={studioEdgeTypes}
        >
          {props.showControls && (
            <StudioControls onBeforeChange={takeSnapshot} onChange={onChange} />
          )}
          <Sidebar
            episodeRef={episodeRef!}
            onNodesChange={onNodesChange}
            onCreateStudioNode={onCreateStudioNode}
          />
          <Background
            lineWidth={rawDimensions.size1}
            gap={rawDimensions.size64}
            variant={BackgroundVariant.Lines}
            style={{ background: '#18181f' }}
            color={'rgba(255, 255, 255, .02)'}
          />

          <svg>
            <defs>
              <ArrowHead color="#B3B3B3" id="arrowhead-default" />
              <ArrowHead color="#7b80ff" id="arrowhead-selected" />
            </defs>
          </svg>
        </ReactFlow>
      </FlowContainer>
    </EmbeddedContainer>
  );
};

const EmbeddedContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`;

const FlowContainer = styled.div`
  width: 100%;
  height: 100%;

  .react-flow__node {
    z-index: -1 !important;
  }

  .react-flow__edge .react-flow__edge-path {
    stroke-opacity: 1;
    stroke-width: 1;
  }
`;
