import { Modal as ChakraModal, ModalContent } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ModalHeader } from './ModalHeader';

type Props = PropsWithChildren<{
  title: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  borderDisabled?: boolean;
  size?: string;
}>;

export const Modal: React.FC<Props> = (props) => {
  const { isOpen, children, onClose, title, size } = props;

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={size ?? '4xl'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <Container className={props.className}>
          <ModalHeader
            title={title}
            onClose={onClose}
            borderDisabled={props.borderDisabled}
          />
          {children}
        </Container>
      </ModalContent>
    </ChakraModal>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ModalOverlay = styled.div`
  position: fixed;
  left: ${dimensions.size0};
  top: ${dimensions.size0};
  width: 100vw;
  height: 100dvh;
  background: ${({ theme }) => theme.colors.base.dark[100]};
  -webkit-backdrop-filter: blur(${dimensions.size8});
  backdrop-filter: blur(${dimensions.size8});
  z-index: 10;
`;
