import { Button, ModalFooter } from '@chakra-ui/react';
import { GlobalAiConfig } from '@common/studio-types';
import { GenerateImageProvider } from '@common/studio-types/generateImage';
import { Field, Textarea } from '@maestro/components';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageProviderSelectionField } from './ImageProviderSelectionField';

type Props = {
  globalAiConfig?: GlobalAiConfig;
  onGlobalAiConfigChange: (config: GlobalAiConfig) => void;
};

export const GlobalAiConfigurationFields: FC<Props> = ({
  onGlobalAiConfigChange,
  globalAiConfig,
}) => {
  const [textPrompt, setGlobalTextPrompt] = useState<string>('');
  const [imagePrompt, setGlobalImagePrompt] = useState<string>('');
  const [negativePrompt, setNegativePrompt] = useState<string>('');
  const [imageProvider, setImageProvider] = useState<GenerateImageProvider>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setGlobalImagePrompt(globalAiConfig?.imagePrompt ?? '');
    setGlobalTextPrompt(globalAiConfig?.textPrompt ?? '');
    setNegativePrompt(globalAiConfig?.negativePrompt ?? '');
    setImageProvider(globalAiConfig?.imageProvider);
    setIsSaving(false);
  }, [globalAiConfig]);

  const saveChanges = () => {
    setIsSaving(true);
    onGlobalAiConfigChange({
      imagePrompt,
      textPrompt,
      imageProvider,
      negativePrompt,
    });
  };

  return (
    <Container>
      <BodyContainer>
        <ImageProviderSelectionField
          value={imageProvider}
          onChange={(selection) => setImageProvider(selection)}
          label="AI Image Provider"
          hint="Pick an ai image provider to generate images, the node level configuration will take precedence over the selected configuration"
        />
        <Field label="Global Text Prompt">
          <Textarea
            placeholder="Tell AI what to create when generating this node"
            value={textPrompt}
            onChange={(e) => setGlobalTextPrompt(e.target.value)}
          />
        </Field>
        <Field label="Global Image Prompt">
          <Textarea
            placeholder="Tell AI what to create when generating this node"
            value={imagePrompt}
            onChange={(e) => setGlobalImagePrompt(e.target.value)}
          />
        </Field>
        <Field label="Negative Prompt">
          <Textarea
            placeholder="Tell AI what to NOT create when generating images"
            value={negativePrompt}
            onChange={(e) => setNegativePrompt(e.target.value)}
          />
        </Field>
      </BodyContainer>
      <FooterContainer>
        <ModalFooter justifyContent="start">
          <Button
            variant="primary"
            onClick={saveChanges}
            isLoading={isSaving}
            isDisabled={isSaving}
          >
            Save
          </Button>
        </ModalFooter>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const BodyContainer = styled.div`
  padding: 16px 16px 0 16px;
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background.default};
`;
