import { dimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

export const NotFound: React.FC = () => {
  return (
    <Container>
      <GradientContainer>
        <Title>404</Title>
        <Body>Sorry, the page you visited does not exist.</Body>
      </GradientContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(
      0deg,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 0%,
      var(--System-Black-200, rgba(0, 0, 0, 0.2)) 100%
    ),
    #21212c;
`;

const GradientContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
`;

const Title = styled.div`
  font-size: ${dimensions.size160};
  font-weight: 700;
  color: white;
`;

const Body = styled.div`
  max-width: ${dimensions.size560};
  margin-top: ${dimensions.size16};
  font-size: ${dimensions.size16};
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
`;
