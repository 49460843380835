import { BadgeProps } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';

type Props = { text: string; badgeProps?: BadgeProps };

export const InterpolatedText: React.FC<Props> = ({ text, badgeProps }) => {
  const { findPropertyByName } = useStudioRpgConfig();

  const texts = useMemo(() => {
    const getProperty = (name: string): React.ReactNode => {
      const property = findPropertyByName(name);

      if (!property) return <Badge $error>prop.{name}</Badge>;

      return <Badge>prop.{name}</Badge>;
    };

    const split = text.split(/(\{[^\}]+\})/g);

    return split.map((part) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const value = part.slice(1, -1);

        if (value.startsWith('prop.series.')) {
          return getProperty(value.slice(12));
        } else if (value.startsWith('prop.episode.')) {
          return getProperty(value.slice(13));
        } else if (value.startsWith('prop.')) {
          return getProperty(value.slice(5));
        } else if (value.startsWith('input.')) {
          return <Badge>prop.{value.slice(6)}</Badge>;
        } else if (value.startsWith('character.')) {
          return <Badge>character.{value.slice(10)}</Badge>;
        } else if (value.startsWith('character1.')) {
          return <Badge>character1.{value.slice(11)}</Badge>;
        } else if (value.startsWith('character2.')) {
          return <Badge>character2.{value.slice(11)}</Badge>;
        }
      }

      return part;
    });
  }, [text, badgeProps, findPropertyByName]);

  return <Text>{texts}</Text>;
};

const Text = styled.div`
  white-space: pre-line;
`;

const Badge = styled.div<{ $error?: boolean }>`
  ${textStyles.body.b12sb}
  display: inline;
  letter-spacing: 0.5px;
  background: ${({ theme, $error }) =>
    $error ? theme.colors.base.red[100] : theme.colors.base.accent[100]};
  color: ${({ theme, $error }) =>
    $error ? theme.colors.base.red[1000] : theme.colors.base.accent[1000]};

  padding: ${dimensions.size0} ${dimensions.size4} ${dimensions.size2}
    ${dimensions.size4};
  border-radius: ${dimensions.size8};
`;
