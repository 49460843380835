import { Button, Tooltip } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Icon } from '@maestro/components/Icon';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useRef, DragEvent, MouseEvent } from 'react';
import { OnNodesChange } from 'reactflow';
import styled from 'styled-components';
import { CreateStudioNode } from '../hooks/maestro.types';
import { nodeConfigList } from '../nodes';
import { GhostNode } from '../nodes/GhostNode';
import { NodeConfig, NodeGroup } from '../nodes/Node.types';
import { useUserPreferencesStore } from '../state/userPreferences';

type Props = {
  episodeRef: string | null;
  onNodesChange: OnNodesChange;
  onCreateStudioNode: CreateStudioNode;
};

export const Sidebar: React.FC<Props> = ({
  episodeRef,
  onCreateStudioNode,
}) => {
  const dragImageRef = useRef<HTMLDivElement>(null);
  const { isRpgModeEnabled, toggleRpgMode } = useUserPreferencesStore(
    ({ isRpgModeEnabled, toggleRpgMode }) => ({
      isRpgModeEnabled,
      toggleRpgMode,
    }),
  );

  const onDragStart = (
    event: DragEvent<HTMLButtonElement>,
    config: NodeConfig<StudioNodeType, StudioNodeData>,
  ) => {
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/reactflow', config.type);

      if (dragImageRef.current) {
        event.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
      }
    }
  };

  const onClickNewNode = (
    event: MouseEvent<HTMLButtonElement>,
    config: NodeConfig<StudioNodeType, StudioNodeData>,
  ) => {
    const { clientX, clientY } = event;
    onCreateStudioNode({
      studioNodeData: config.createNodeData(),
      args: { x: clientX + 20, y: clientY - 15 },
      autoCenter: false,
    });
  };

  const isPropertiesMenuEnabled = useFeatureFlag(FeatureFlags.PropertiesMenu);
  const filteredNodeList = nodeConfigList.filter((node) => {
    if (isPropertiesMenuEnabled && node.type === StudioNodeType.EpisodeSetup) {
      return false;
    }

    return (
      (node.group !== NodeGroup.Advanced || isRpgModeEnabled) &&
      (!node.episodeRef || node.episodeRef === episodeRef) &&
      (!node.featureFlag || useFeatureFlag(node.featureFlag))
    );
  });

  return (
    <OuterContainer>
      <StyledGhostNode ref={dragImageRef} />
      <Container>
        {filteredNodeList.map((config) => (
          <Tooltip key={config.type} label={config.name} placement="right">
            <Button
              draggable
              onClick={(event) => onClickNewNode(event, config)}
              onDragStart={(event) => onDragStart(event, config)}
              variant="sidebar"
            >
              <Icon name={config.icon} size={rawDimensions.size16} />
            </Button>
          </Tooltip>
        ))}
        <Tooltip
          closeOnPointerDown
          label={`${isRpgModeEnabled ? 'Hide' : 'Show'} advanced`}
          placement="right"
        >
          <Button
            onClick={() => toggleRpgMode(!isRpgModeEnabled)}
            variant="sidebar"
          >
            <AdvancedIcon
              name={isRpgModeEnabled ? 'chevron-up' : 'chevron-down'}
              size={rawDimensions.size16}
              $open={isRpgModeEnabled}
            />
          </Button>
        </Tooltip>
      </Container>
    </OuterContainer>
  );
};

const StyledGhostNode = styled(GhostNode)`
  position: absolute;
  top: -10000px;
  left: -10000px;
`;

const OuterContainer = styled.div`
  position: absolute;
  top: 25%;
  left: ${dimensions.size16};
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  padding: ${dimensions.size4};
  margin-top: -25%;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${dimensions.size8};
`;

const AdvancedIcon = styled(Icon)<{ $open: boolean }>`
  background-color: ${({ theme, $open }) =>
    $open ? theme.colors.background.shade : theme.colors.background.accent};
  padding: ${dimensions.size2};
  height: unset;
  width: unset;
  border-radius: ${dimensions.size4};
`;
