import { Badge } from '@chakra-ui/react';
import { StoryFactoryBranchShortFragment } from '@maestro/graphql';

type Props = { story: StoryFactoryBranchShortFragment };

export const StoryStatus: React.FC<Props> = ({ story }) => {
  return story.publishedAt ? (
    <Badge variant="statusActive">Published</Badge>
  ) : (
    <Badge variant="statusInactive">Draft</Badge>
  );
};
