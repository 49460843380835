import { Button, Tooltip } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { rawDimensions } from '@maestro/styles';
import { useDrawerStore } from '../hooks/useDrawerStore';

export const PlayEpisodeButton = () => {
  const { openDrawer } = useDrawerStore();

  const playOnBrowser = () => openDrawer('play-episode');

  return (
    <Tooltip label="Play episode" placement="bottom-end">
      <Button
        variant="primary"
        borderRadius={rawDimensions.size8}
        padding={rawDimensions.size0}
        onClick={playOnBrowser}
      >
        <Icon name="play" size={rawDimensions.size16} />
      </Button>
    </Tooltip>
  );
};
