import { breakpoints, dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${({ theme }) => theme.colors.text.header};
  background: ${({ theme }) => theme.colors.background.default};
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};

  padding: ${dimensions.size48};
  gap: ${dimensions.size24};

  @media ${breakpoints.tablet} {
    padding: ${dimensions.size12};
    gap: ${dimensions.size12};
  }
`;

export const HeaderRowContainer = styled.div`
  display: flex;
  gap: ${dimensions.size24};
  align-items: end;
`;

export const HeaderImage = styled.img<{ $width: number; $height?: number }>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
  align-items: flex-start;
`;

export const HeaderVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${dimensions.size8};
`;

export const HeaderTitle = styled.div`
  ${textStyles.title.t40b}

  @media ${breakpoints.tablet} {
    ${textStyles.title.t20b}
  }
`;

export const HeaderMetadata = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;
