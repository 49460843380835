import { useFormControlProps } from '@chakra-ui/react';
import { dimensions, textStyles } from '@maestro/styles';
import {
  TextareaAutosize,
  TextareaAutosizeProps,
} from '@mui/base/TextareaAutosize';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = TextareaAutosizeProps & {
  autoFocus?: boolean;
  isInvalid?: boolean;
  maxCharacters?: number;
  placeholder?: string;
  fillHeight?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (props, outerRef) => {
    const innerRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(outerRef, () => innerRef.current!, []);

    const count = (typeof props.value === 'string' && props.value?.length) || 0;
    const [isFocused, setIsFocused] = useState(false);
    const { maxCharacters, ...restProps } = props;
    const isWarning = !!props.maxCharacters && count > props.maxCharacters;
    const { isInvalid, isRequired, isReadOnly, isDisabled, ...control } =
      useFormControlProps(restProps);

    return (
      <Container
        $isFocused={isFocused}
        $isWarning={isWarning}
        $isInvalid={isInvalid}
        onClick={() => innerRef.current?.focus?.()}
        $fillHeight={props?.fillHeight}
      >
        <StyledTextarea
          required={isRequired}
          readOnly={isReadOnly}
          disabled={isDisabled}
          ref={innerRef}
          className={props.className}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          {...control}
          onBlur={(evt) => {
            control?.onBlur?.(evt);
            setIsFocused(false);
          }}
          onFocus={(evt) => {
            control?.onFocus(evt);
            setIsFocused(true);
          }}
        />
        {!!props.maxCharacters && (
          <CharacterCount
            $isWarning={isWarning}
            $isFocused={isFocused}
            $isInvalid={isInvalid}
          >
            {count}/{maxCharacters}
          </CharacterCount>
        )}
      </Container>
    );
  },
);

const Container = styled.div<{
  $isFocused: boolean;
  $isInvalid?: boolean;
  $isWarning: boolean;
  $fillHeight: Props['fillHeight'];
}>`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
  padding: ${dimensions.size12};
  cursor: text;
  caret-color: ${({ theme }) => theme.colors.text.accent};
  background: ${({ theme }) => theme.colors.background.shade};
  border: ${dimensions.size1} solid transparent;
  border-radius: ${dimensions.size4};
  transition: border-color 0.2s;

  ${({ $fillHeight }) => $fillHeight && 'flex: 1;'}

  ${({ $isWarning, $isFocused, $isInvalid, theme }) =>
    $isWarning
      ? `border-color: ${theme.colors.warning};`
      : $isFocused
        ? `border-color: ${theme.colors.border.accent[1000]};`
        : $isInvalid && `border-color: ${theme.colors.border.error[1000]};`}
`;

const StyledTextarea = styled(TextareaAutosize)`
  ${textStyles.body.b14m}
  resize: none;
  background: transparent;
  outline: 0;
`;

const CharacterCount = styled.div<{
  $isWarning: boolean;
  $isFocused: boolean;
  $isInvalid?: boolean;
}>`
  ${textStyles.label.lb10sb}
  display: flex;
  justify-content: flex-end;
  color: ${({ $isWarning, $isFocused, $isInvalid, theme }) =>
    $isWarning
      ? theme.colors.warning
      : $isFocused
        ? theme.colors.text.accent
        : $isInvalid
          ? theme.colors.border.error[1000]
          : theme.colors.text.placeholder};
  transition: color 0.2s;
`;
