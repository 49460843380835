import { Button } from '@chakra-ui/react';
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@maestro/components';
import { FieldDefinition, FieldType } from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { FieldForm } from './FieldForm';

type Props = PropsWithChildren<{
  onAddField: (field: FieldDefinition) => void;
}>;

export const AddFieldButton: React.FC<Props> = (props) => {
  const { onAddField } = props;
  const [field, setField] = useState<FieldDefinition | undefined>();

  const onClose = () => setField(undefined);
  const onSave = (field: FieldDefinition) => {
    onAddField(field);
    onClose();
  };

  const onAdd = (type: FieldType) => {
    setField({
      type,
      name: '',
      id: '',
      description: '',
      required: false,
      options: type === FieldType.Select ? [''] : undefined,
    });
  };

  return (
    <>
      <FieldForm
        onChange={onSave}
        isOpen={!!field}
        onClose={onClose}
        data={field}
      />
      <Menu>
        <MenuButton>
          <Button variant="default">Add new field</Button>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => onAdd(FieldType.Number)}>
            <MenuContainer>
              <Icon name="number-type" size={rawDimensions.size20} />
              <ValueContainer>
                <Name>Number</Name>
                <Description>
                  Used to store numeric values, like strength, health, etc.
                </Description>
              </ValueContainer>
            </MenuContainer>
          </MenuItem>

          <MenuItem onClick={() => onAdd(FieldType.Text)}>
            <MenuContainer>
              <Icon name="text-type" size={rawDimensions.size20} />

              <ValueContainer>
                <Name>Text</Name>
                <Description>
                  Used to store text values, like character name, etc.
                </Description>
              </ValueContainer>
            </MenuContainer>
          </MenuItem>
          <MenuItem onClick={() => onAdd(FieldType.Select)}>
            <MenuContainer>
              <Icon name="enum-type" size={rawDimensions.size20} />
              <ValueContainer>
                <Name>Choice</Name>
                <Description>
                  Typically used to store an option on a list, like race,
                  character class, profession, etc.
                </Description>
              </ValueContainer>
            </MenuContainer>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${dimensions.size12};
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Description = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.body};
`;
