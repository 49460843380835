import { StoryFactoryFragment } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
  fields: StoryFactoryFragment['fields'];
  className?: string;
};

export const InterpolatedText: React.FC<Props> = ({
  text,
  fields,
  className,
}) => {
  const fieldMap = useMemo(() => {
    return fields.reduce(
      (acc, field) => ({ ...acc, [field.id]: field }),
      {} as Record<string, StoryFactoryFragment['fields'][number]>,
    );
  }, [fields]);

  const texts = useMemo(() => {
    const getProperty = (name: string, index: number): React.ReactNode => {
      const property = fieldMap[name];

      if (!property) {
        return (
          <Badge $error key={index}>
            prop.{name}
          </Badge>
        );
      }

      return <Badge key={index}>prop.{name}</Badge>;
    };

    const split = text.split(/(\{[^\}]+\})/g);

    return split.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const value = part.slice(1, -1);

        if (value.startsWith('prop.')) {
          return getProperty(value.slice(5), index);
        } else if (value.startsWith('character.')) {
          return <Badge key={index}>character.{value.slice(10)}</Badge>;
        }
      }

      return part;
    });
  }, [text, fieldMap]);

  return <Text className={className}>{texts}</Text>;
};

const Text = styled.div`
  white-space: pre-line;
`;

const Badge = styled.div<{ $error?: boolean }>`
  ${textStyles.body.b16sb}
  display: inline;
  background: ${({ theme, $error }) =>
    $error ? theme.colors.base.red[100] : theme.colors.base.accent[100]};
  color: ${({ theme, $error }) =>
    $error ? theme.colors.base.red[1000] : theme.colors.base.accent[1000]};

  padding: ${dimensions.size0} ${dimensions.size8} ${dimensions.size4}
    ${dimensions.size8};
  border-radius: ${dimensions.size8};
`;
