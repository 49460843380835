import { toOptimizedImageUri } from '@common/image';
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TopBar,
} from '@maestro/components';
import { useLogoutMutation } from '@maestro/graphql';
import {
  slideInLeftToRightAnimation,
  slideOutLeftToRightAnimation,
} from '@maestro/studio/components/simulator/gameMessages/keyframes';
import {
  breakpoints,
  dimensions,
  rawDimensions,
  textStyles,
} from '@maestro/styles';
import { useGetIdentity, useLogout, useNavigation } from '@refinedev/core';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useIsAdminUser } from '../useIsAdminUser';

export const MainContainer = () => {
  const navigation = useNavigation();
  const { mutateAsync: refineLogout } = useLogout();
  const { mutateAsync: studioLogout } = useLogoutMutation();
  const { data } = useGetIdentity<{
    id: string;
    username?: string;
    email?: string;
    user_metadata: {
      avatar_url?: string;
      name?: string;
      email?: string;
    };
  }>();
  const isAdmin = useIsAdminUser();
  const { pathname } = useLocation();

  const pathMatches = (path: string) => pathname.startsWith(path);
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const bodyRef = useRef<HTMLDivElement>();
  const natigateTo = (path: string) => {
    setIsExpanded(false);
    navigation.push(path);
  };
  const [backdropTop, setBackdropTop] = useState(0);
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    setBackdropTop(bodyRef.current?.scrollTop ?? 0);
  };

  useEffect(() => {
    // necessary to not show the closing animation when mounted
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  return (
    <Root>
      <TopBar
        logoConfig={{
          onLogoClick: () => navigation.push('/'),
          clickLocation: 'Home',
        }}
        isExpanded={isExpanded}
        onExpandClick={toggleExpanded}
      >
        {!!data && (
          <Menu>
            <MenuButton>
              <UserData id="user-menu">
                {data.user_metadata?.avatar_url ? (
                  <UserImage
                    src={toOptimizedImageUri(data.user_metadata.avatar_url, {
                      width: rawDimensions.size24,
                      height: rawDimensions.size24,
                      dpr: window.devicePixelRatio,
                    })}
                  />
                ) : (
                  <UserImagePlaceholder />
                )}
                {data.username ??
                  data.user_metadata?.name ??
                  data.user_metadata?.email}
              </UserData>
            </MenuButton>

            <MenuList>
              <MenuItem onClick={() => natigateTo('/username')}>
                Set Username
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await studioLogout({});
                  await refineLogout();
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </TopBar>
      <Containers>
        <Sidebar
          $visible={isVisible}
          className={isExpanded ? 'open' : 'closed'}
        >
          <SidebarMenu>
            <SidebarMenuItem
              id="home-page-sidebar-link"
              $isActive={pathname === '/'}
              onClick={() => natigateTo('/')}
            >
              <Icon name="home" size={rawDimensions.size20} />
              Home
            </SidebarMenuItem>
            <SidebarMenuItem
              id="my-studio-sidebar-link"
              $isActive={pathMatches('/my-studio')}
              onClick={() => natigateTo('/my-studio')}
            >
              <Icon name="edit-drafts" size={rawDimensions.size20} />
              My Studio
            </SidebarMenuItem>
            {isAdmin && (
              <SidebarMenuItem
                $isActive={pathMatches('/discovery-sections')}
                onClick={() => natigateTo('/discovery-sections')}
              >
                <Icon name="star-stack-filled" size={rawDimensions.size20} />
                Discovery Sections
              </SidebarMenuItem>
            )}
            {isAdmin && (
              <SidebarMenuItem
                $isActive={pathMatches('/story-factory')}
                onClick={() => natigateTo('/story-factory')}
              >
                <Icon name="ai-generate" size={rawDimensions.size20} />
                Story Factories
              </SidebarMenuItem>
            )}
            {isAdmin && (
              <SidebarMenuItem
                $isActive={
                  pathMatches('/story') && !pathMatches('/story-factory')
                }
                onClick={() => natigateTo('/story')}
              >
                <Icon name="sheet" size={rawDimensions.size20} />
                Stories
              </SidebarMenuItem>
            )}
            {isAdmin && (
              <SidebarMenuItem
                $isActive={pathMatches('/series/all')}
                onClick={() => natigateTo('/series/all')}
              >
                <Icon name="get-value" size={rawDimensions.size20} />
                All Series
              </SidebarMenuItem>
            )}
          </SidebarMenu>
        </Sidebar>
        <Body ref={bodyRef as never}>
          {isExpanded && (
            <Backdrop $top={backdropTop} onClick={toggleExpanded} />
          )}
          <Outlet />
        </Body>
      </Containers>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
`;

const Containers = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Backdrop = styled.div<{ $top: number }>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${dimensions.size0};
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.base.dark[30]};
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 10;
`;

const Sidebar = styled.div<{ $visible: boolean }>`
  width: ${dimensions.size240};
  height: 100%;
  background: ${({ theme }) => theme.colors.background.default};
  border-right: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 100%;
    height: calc(100dvh - ${dimensions.size56});
    top: 56px;
    left: 0;
    z-index: 25;
    background: ${({ theme }) => theme.colors.base.dark['1000']};
    visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

    &.open {
      animation: ${slideInLeftToRightAnimation} 0.2s ease forwards;
    }

    &.closed {
      animation: ${slideOutLeftToRightAnimation} 0.2s ease forwards;
    }
  }
`;

const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  padding: ${dimensions.size16};
`;

const SidebarMenuItem = styled.div<{ $isActive?: boolean }>`
  ${textStyles.body.b14sb}
  display: flex;
  gap: ${dimensions.size8};
  align-items: center;
  padding: ${dimensions.size8} ${dimensions.size16};
  cursor: pointer;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.background.shade : 'transparent'};
  border-radius: ${dimensions.size8};
  transition: background 0.2s;

  &:hover {
    background: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.background.shade : theme.colors.base.light[30]};
  }
`;

const Body = styled.div`
  flex: 1;
  overflow: scroll;
  position: relative;
`;

const UserData = styled.div`
  ${textStyles.body.b14sb}
  display: flex;
  gap: ${dimensions.size8};
  align-items: center;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: ${dimensions.size24};
  height: ${dimensions.size24};
  border-radius: 50%;
`;

const UserImagePlaceholder = styled.div`
  width: ${dimensions.size24};
  height: ${dimensions.size24};
  background: ${({ theme }) => theme.colors.base.light[100]};
  border-radius: 50%;
`;
