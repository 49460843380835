import { NodeAnalyticsData } from '@common/studio-types';
import type { StatefulGame } from './statefulGame';

export const getCurrentNodeAnalyticsData = (
  game: StatefulGame,
): NodeAnalyticsData | undefined => {
  const state = game.state();
  const currentNodeId = state.currentNodeId;

  if (!currentNodeId) {
    return undefined;
  }

  const currentNode = game.gameNodes.nodeById(currentNodeId);

  return currentNode?.analyticsData;
};
