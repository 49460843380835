import { Button, ModalFooter } from '@chakra-ui/react';
import { Icon, Modal, ModalBody } from '@maestro/components';
import { StoryFactoryBranchFragment } from '@maestro/graphql';
import { useGenerateAiEpisode } from '@maestro/studio/components/properties/rpg/generation/useGenerateAiEpisode';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { StoryForm } from './StoryForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  story: StoryFactoryBranchFragment;
};

export const PlayStoryModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, story } = props;
  const [values, setValues] = useState<Record<string, string | number>>({});
  const { generate, isGenerating } = useGenerateAiEpisode(story.id);

  const onFieldsChange = (fieldId: string, value: string | number) => {
    setValues({ ...values, [fieldId]: value });
  };

  const fieldsDefinition = useMemo(() => {
    const newValues: Record<string, string> = {};

    story.fields.forEach(({ fieldId, value }) => {
      const fieldDef = story.storyFactory.fields.find(
        ({ id }) => id === fieldId,
      );

      if (fieldDef) {
        newValues[fieldDef.id] = value;
      }
    });

    setValues(newValues);

    return story.storyFactory.fields.filter(
      (field) => !story.fields.some(({ fieldId }) => field.id === fieldId),
    );
  }, [story]);

  const needsFields = fieldsDefinition.length > 0;
  const isValid = useMemo(() => {
    const keys = Object.keys(values);
    const fieldDefKeys = fieldsDefinition.map(({ id }) => id);
    const allFieldsFilled = fieldDefKeys.every((key) => !!values[key]);

    return keys.every((key) => !!values[key]) && allFieldsFilled;
  }, [values, fieldsDefinition]);

  return (
    <Modal title="Play story" isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalBody>
        <Container>
          <ContentColumn $needsFields={needsFields}>
            {!needsFields && (
              <Description>
                You're going to play <strong>{story.title}</strong>
              </Description>
            )}

            {needsFields && (
              <StoryForm
                allowEmpty={false}
                fieldDefinition={fieldsDefinition}
                values={values}
                onChange={onFieldsChange}
              />
            )}
          </ContentColumn>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
        <Button
          isLoading={isGenerating}
          isDisabled={needsFields && !isValid}
          leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
          variant="primary"
          onClick={() => generate(values)}
        >
          Play
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${dimensions.size24};
  justify-content: space-around;
  align-items: center;
  padding: ${dimensions.size24};
`;

const ContentColumn = styled.div<{ $needsFields: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $needsFields }) => ($needsFields ? 'normal' : 'center')};
  gap: ${dimensions.size12};
  flex: 1;
`;

const Description = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.header};
`;
