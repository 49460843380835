import { Icon } from '@maestro/components/Icon';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { HTMLAttributes } from 'react';
import { HandleProps, Position, Handle as RFHandle } from 'reactflow';
import styled from 'styled-components';
import { useHandle, useOnAddNodeClick } from './Handle.hooks';

type StudioHandleProps = {
  nodeId: string;
  label?: string;
  isHidden?: boolean;
};

type Props = StudioHandleProps &
  HandleProps &
  Omit<HTMLAttributes<HTMLDivElement>, 'id'>;

export const Handle: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { nodeId, ...rest } = props;
  const handle = useHandle(props);
  const onAddNodeClick = useOnAddNodeClick(props);

  return (
    <Container
      $position={props.position}
      style={{
        ...props.style,
        visibility: props.isHidden ? 'hidden' : 'visible',
      }}
      onMouseOver={handle.onMouseOver}
      onMouseLeave={handle.onMouseLeave}
    >
      {props.type === 'source' && (
        <PlusButton
          $position={props.position}
          onClick={onAddNodeClick}
          $visible={handle.buttonVisible}
        >
          <Icon name="plus" size={rawDimensions.size16} />
        </PlusButton>
      )}
      <StyledReactFlowHandle
        {...rest}
        isValidConnection={handle.isValidConnection}
      ></StyledReactFlowHandle>
    </Container>
  );
};

const StyledReactFlowHandle = styled(RFHandle)`
  position: relative;
  align-items: center;
  width: ${dimensions.size36};
  height: ${dimensions.size36};
  background: transparent;
  border: ${dimensions.size0};

  &:after {
    position: absolute;
    top: 15px;
    left: 15px;
    width: ${dimensions.size6};
    height: ${dimensions.size6};
    content: ' ';
    background: ${({ theme }) => theme.colors.base.light[900]};
    border-radius: ${dimensions.size16};
  }
`;

const PlusButton = styled.div<{ $position: Position; $visible: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: ${dimensions.size32};
  height: ${dimensions.size32};
  color: white;
  cursor: pointer;

  background: black;
  border-radius: 999px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: all 0.2s ease;

  ${({ $position }) =>
    [Position.Top, Position.Bottom].includes($position) &&
    'left: calc(50% - 16px);'}

  ${({ $position }) =>
    [Position.Left, Position.Right].includes($position) &&
    'bottom: calc(50% - 16px);'}

  ${({ $position }) => $position === Position.Bottom && 'bottom: -47px;'}
  ${({ $position }) => $position === Position.Left && 'left: -37px;'}
  ${({ $position }) => $position === Position.Right && 'right: -47px;'}
`;

const Container = styled.div<{ $position: Position }>`
  position: absolute;
  width: ${dimensions.size24};
  height: ${dimensions.size24};

  ${({ $position }) => $position === Position.Top && 'top: -26px;'}
  ${({ $position }) => $position === Position.Bottom && 'bottom: -13px;'}
  ${({ $position }) => $position === Position.Left && 'left: -42px;'}
  ${({ $position }) => $position === Position.Right && 'right: -30px;'}

  ${({ $position }) =>
    [Position.Top, Position.Bottom].includes($position) &&
    'left: calc(50% - 11px);'}

  ${({ $position }) =>
    [Position.Left, Position.Right].includes($position) &&
    'bottom: calc(50% - 11px);'}
`;
