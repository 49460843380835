import { Button, ButtonGroup } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  Link,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import { useGetStoryFactoryQuery } from '@maestro/graphql';
import { textStyles } from '@maestro/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { FieldsList } from './FieldsList';
import { InterpolatedText } from './InterpolatedText';

export const StoryFactoryDetails: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryQuery({ id: id! });

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data?.storyFactory) {
    return <NotFound />;
  }

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>{data.storyFactory.title}</HeaderTitle>
          <ButtonGroup>
            <Link to={`/story-factory/${id}/edit`}>
              <Button variant="default">Edit</Button>
            </Link>
            <Link to={`/story-factory/${id}/new`}>
              <Button variant="primary">Create Story</Button>
            </Link>
          </ButtonGroup>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Form Fields</SectionTitle>
        <SectionCard>
          <FieldsList fields={data.storyFactory.fields} />
        </SectionCard>
        <SectionTitle>Prompt</SectionTitle>
        <SectionCard>
          <Prompt>
            <InterpolatedText
              text={data.storyFactory.prompt}
              fields={data.storyFactory.fields}
            />
          </Prompt>
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  align-items: center;
`;

const Prompt = styled.div`
  ${textStyles.body.b16m}
  white-space: pre-line;
`;
