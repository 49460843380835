import { Button, ButtonGroup, Checkbox } from '@chakra-ui/react';
import { Icon, SectionCard } from '@maestro/components';
import { dimensions, textStyles } from '@maestro/styles';
import { Reorder, useDragControls } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DeleteDropdown } from './DeleteDropdown';
import { DiscoverySeriesCard } from './DiscoverySeriesCard';
import { useGetSectionSeries } from './hooks/useGetSectionSeries';
import { useSaveSection } from './hooks/useSaveSection';
import { SectionSeries, Story } from './sections.type';
import { SeriesDropdown } from './SeriesDropdown';

type Props = {
  allSeries: Story[];
  section: SectionSeries;
  onSave: (section: SectionSeries) => void;
  onRemove: () => void;
};

export const DiscoverySectionSeries: React.FC<Props> = (props) => {
  const { section, onSave, allSeries, onRemove } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [isDirtySeries, setIsDirtySeries] = useState(false);
  const [dirtyVersion, setDirtyVersion] = useState(section);
  const controls = useDragControls();
  const { series, updateSeries } = useGetSectionSeries(section);
  const seriesIds = useMemo(() => series.map((item) => item.id), [series]);
  const dropdownSeries = useMemo(
    () =>
      allSeries.filter(
        (item) => !seriesIds.includes(item.id) && item.coverImage,
      ),
    [allSeries, seriesIds],
  );
  const { saveSection, isSaving } = useSaveSection();

  useEffect(() => {
    setDirtyVersion((dirtyVersion) =>
      !dirtyVersion ? section : { ...dirtyVersion, order: section.order },
    );
  }, [section]);

  const editSeries = (series: Story[]) => {
    updateSeries(series);
    setIsDirty(true);
    setIsDirtySeries(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyVersion({ ...dirtyVersion, name: e.target.value });
    setIsDirty(true);
  };

  const handleOnlyForKidsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyVersion({
      ...dirtyVersion,
      payload: { ...dirtyVersion.payload, onlyForKids: e.target.checked },
    });
    setIsDirty(true);
  };

  const onSaveButtonPress = async () => {
    await saveSection(dirtyVersion, isDirtySeries ? series : undefined);

    const newDirtyVersion: SectionSeries = {
      ...dirtyVersion,
      payload: {
        series: series.map(({ id, title, coverImage }) => ({
          id,
          title,
          media: { main: coverImage },
        })),
        onlyForKids: dirtyVersion.payload.onlyForKids,
      },
    };
    setDirtyVersion(newDirtyVersion);
    onSave(newDirtyVersion);

    setIsDirty(false);
    setIsDirtySeries(false);
  };

  return (
    <Reorder.Item value={section} dragListener={false} dragControls={controls}>
      <StyledSectionCard id={`discovery-section-${section.id}`}>
        <DiscoverySectionContainer>
          <DiscoverySectionName>
            <TitleContainer>
              <DragHandle
                className="reorder-handle"
                onPointerDown={(e) => controls.start(e)}
              >
                <Icon name="menu" size={20} />
              </DragHandle>{' '}
              <EditableContainer
                onChange={handleChange}
                type="text"
                value={dirtyVersion.name ?? ''}
              />
            </TitleContainer>
            <ButtonGroup>
              <DeleteDropdown onRemove={onRemove} />
              <SeriesDropdown
                series={dropdownSeries}
                onSelect={(item) => editSeries([item, ...series])}
              />
              <Checkbox
                onChange={handleOnlyForKidsChange}
                isChecked={dirtyVersion.payload.onlyForKids}
              >
                Kids-friendly only
              </Checkbox>

              <Button
                size="sm"
                variant="primary"
                onClick={onSaveButtonPress}
                isDisabled={!isDirty}
                isLoading={isSaving}
              >
                Save
              </Button>
            </ButtonGroup>
          </DiscoverySectionName>
          <SeriesScrollContainer>
            <Reorder.Group axis="x" values={series} onReorder={editSeries}>
              <DiscoverySectionSeriesContainer>
                {series.map((item) => (
                  <DiscoverySeriesCard
                    key={item.id}
                    series={item}
                    onRemove={() => {
                      editSeries(series.filter(({ id }) => id !== item.id));
                    }}
                  />
                ))}
              </DiscoverySectionSeriesContainer>
            </Reorder.Group>
          </SeriesScrollContainer>
        </DiscoverySectionContainer>
      </StyledSectionCard>
    </Reorder.Item>
  );
};

const StyledSectionCard = styled(SectionCard)`
  margin-bottom: ${dimensions.size16};
`;

const DiscoverySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  align-items: start;
  padding: ${dimensions.size8};
`;

const DiscoverySectionName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.header};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const EditableContainer = styled.input`
  ${textStyles.title.t20b}
  padding: ${dimensions.size0};

  resize: none;
  background: none;
  outline: none;
`;

const DragHandle = styled.div`
  padding: ${dimensions.size8} ${dimensions.size4} ${dimensions.size4}
    ${dimensions.size4};
  cursor: grab;
`;

const SeriesScrollContainer = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 344px;
  min-height: 344px;
  max-height: 344px;
  overflow-y: scroll;
`;

const DiscoverySectionSeriesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;
