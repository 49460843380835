import { useEffect, useState } from 'react';
import { SectionSeries, Story } from '../sections.type';

type Result = {
  series: Story[];
  updateSeries: (rows: Story[]) => void;
};

export const useGetSectionSeries = (section: SectionSeries): Result => {
  const [series, setSeries] = useState<Story[]>([]);

  useEffect(
    () =>
      setSeries(
        section.payload.series.map((series) => ({
          id: series.id,
          coverImage: series.media.main,
          title: series.title,
        })),
      ),
    [section.payload.series],
  );

  const updateSeries = (rows: Story[]) => setSeries(rows);

  return { series, updateSeries };
};
