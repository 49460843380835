import {
  GlobalAiConfig,
  RpgConfig,
  RpgConfigAiGeneration,
  RpgConfigHudElement,
  RpgConfigProperty,
  ValueType,
} from '@common/studio-types';
import { Tab, TabBar } from '@maestro/components';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { useUpdateRpgConfigMutation } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from '../../../hooks/useDebounce';
import { useEpisodeProperties } from '../../../hooks/useEpisodeProperties';
import { useItems } from '../../../hooks/useItems';
import { useStudioFlowStore } from '../../../hooks/useStudioFlowStore';
import { GlobalAiConfigurationFields } from '../../ai/GlobalAiConfigurationFields';
import { AiGenerationConfiguration } from './generation/AiGenerationConfiguration';
import { HudConfiguration } from './HudConfiguration';
import { PropertiesForm } from './PropertiesForm';

type Props = {
  onStudioFlowChange: () => void;
};

export const SeriesRpgConfiguration: React.FC<Props> = ({
  onStudioFlowChange,
}) => {
  const { rpgConfig, seriesId, setRpgConfig } = useStudioFlowStore();
  const { mutate } = useUpdateRpgConfigMutation();
  const [currentTab, setCurrentTab] = useState('seriesProperties');
  const [properties, setProperties] = useState<RpgConfigProperty[]>(
    rpgConfig.properties ?? [],
  );
  const [hudElements, setHudElements] = useState<RpgConfigHudElement[]>(
    rpgConfig.hud ?? [],
  );
  const [aiGeneration, setAiGeneration] = useState<RpgConfigAiGeneration[]>(
    rpgConfig.aiGeneration ?? [],
  );
  const { items, isLoading, refetch } = useItems({ seriesId });
  const [episodeProperties, setEpisodeProperties] = useEpisodeProperties({
    onStudioFlowChange,
  });

  const onSave = useDebounce(
    (changes: Partial<RpgConfig>) => {
      if (rpgConfig && seriesId) {
        const newConfig: RpgConfig = { ...rpgConfig, ...changes };
        mutate({ input: { seriesId, rpgConfig: newConfig } });
        setRpgConfig(newConfig);
      }
    },
    [],
    500,
  );

  useEffect(() => {
    if (rpgConfig) {
      setProperties(rpgConfig.properties);
      setHudElements(rpgConfig.hud);
    }
  }, [rpgConfig]);

  const onHudElementsChange = (elements: RpgConfigHudElement[]) => {
    setHudElements(elements);
    onSave({ hud: elements });
  };

  const onAiGenerationsChange = (elements: RpgConfigAiGeneration[]) => {
    setAiGeneration(elements);
    onSave({ aiGeneration: elements });
  };

  const onGlobalAiConfigChange = (nextConfig: GlobalAiConfig) => {
    onSave({ globalAiConfig: nextConfig });
  };

  const isAiTemplateEpisodes = useFeatureFlag(FeatureFlags.AiTemplateEpisodes);

  const tabs = [
    { label: 'Series', value: 'seriesProperties' },
    // { label: 'Episode', value: 'episodeProperties' },
    isAiTemplateEpisodes && {
      label: 'Episode Generation',
      value: 'episodeGeneration',
    },
    isAiTemplateEpisodes && {
      label: 'AI Configuration',
      value: 'aiConfig',
    },
    // { label: 'Player menu', value: 'hud' },
  ].filter(Boolean);

  const onSeriesPropertiesChange = (
    newProperties: RpgConfigProperty[],
    removedProperties: RpgConfigProperty[],
  ) => {
    setProperties(newProperties);

    if (removedProperties.length > 0) {
      const removedPropertiesRef = removedProperties.map(({ id }) => id);
      const removedPropertiesHudElements = hudElements.filter(
        (element) =>
          element.value.type === ValueType.Property &&
          removedPropertiesRef.includes(element.value.ref),
      );

      if (removedPropertiesHudElements.length > 0) {
        const newHudElements = hudElements.filter(
          (element) => !removedPropertiesHudElements.includes(element),
        );
        setHudElements(newHudElements);
        onSave({ properties: newProperties, hud: newHudElements });
      } else {
        onSave({ properties: newProperties });
      }
    } else {
      onSave({ properties: newProperties });
    }
  };

  return (
    <Container>
      <TabContainer>
        <TabBar>
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              isActive={currentTab === tab.value}
              onClick={() => setCurrentTab(tab.value)}
            >
              {tab.label}
            </Tab>
          ))}
        </TabBar>
      </TabContainer>
      <ScrollContainer>
        {isAiTemplateEpisodes && currentTab === 'episodeGeneration' && (
          <AiGenerationConfiguration
            properties={properties}
            aiGenerations={aiGeneration}
            onAiGenerationsChange={onAiGenerationsChange}
          />
        )}
        {currentTab === 'seriesProperties' && (
          <PropertiesForm
            rpgConfig={rpgConfig}
            properties={properties}
            onChange={onSeriesPropertiesChange}
          />
        )}
        {currentTab === 'episodeProperties' && (
          <PropertiesForm
            episodeScopedOnly
            rpgConfig={rpgConfig}
            onChange={setEpisodeProperties}
            properties={episodeProperties}
          />
        )}
        {currentTab === 'aiConfig' && (
          <GlobalAiConfigurationFields
            onGlobalAiConfigChange={onGlobalAiConfigChange}
            globalAiConfig={rpgConfig.globalAiConfig}
          />
        )}
        {currentTab === 'hud' && (
          <HudConfiguration
            hudElements={hudElements}
            properties={properties}
            onHudElementsChange={onHudElementsChange}
            refetchItems={refetch}
            isLoading={isLoading}
            items={items}
          />
        )}
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  flex: 1;
  max-height: 85dvh;
`;

const TabContainer = styled.div`
  border-bottom: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  flex: 1;
`;
