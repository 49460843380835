import { useGetUserId } from '@maestro/components';
import { toSupabaseClient } from '@maestro/supabase';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Series, SeriesTableRow } from '../types';

type Args = {
  userCreatedOnly?: boolean;
  listAll?: boolean;
  pauseFetch?: boolean;
  worldId?: string;
  search?: string;
};

type Result = {
  series: Series[];
  isLoading: boolean;
  hasMore: boolean;
  fetchMore: () => void;
};

const PAGE_SIZE = 10;

export const useListSeries = (args?: Args): Result => {
  const userId = useGetUserId();
  const [series, setSeries] = useState<Series[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const page = useRef(0);

  const fetchSeries = useCallback(
    async (pageNumber: number) => {
      if (!userId) return;

      setIsLoading(true);

      try {
        let query = toSupabaseClient().from('series').select('*, genre(title)');

        if (args?.userCreatedOnly) {
          query = query.eq('created_by_id', userId);
        }

        if (args?.worldId) {
          query = query.eq('world_id', args.worldId);
        }

        if (args?.search) {
          query = query.textSearch('title', args.search);
        }

        const { data, error } = await query
          .order('created_at', { ascending: false })
          .range((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE)
          .returns<SeriesTableRow[]>();

        if (error) {
          // eslint-disable-next-line no-console
          console.log('Error fetching series', JSON.stringify(error, null, 2));
        }

        if (data && data.length > PAGE_SIZE) {
          setHasMore(true);
          data.pop(); // take the last item
        } else {
          setHasMore(false);
        }

        const resultSeries = (data ?? []).map((row) => ({
          id: row.id,
          genre: row.genre?.title ?? '',
          genreId: row.genre_id,
          title: row.title,
          description: row.description,
          worldId: row.world_id,
          worldTitle: row.world?.title,
          coverUrl: row.media.main,
          fromAuthor: row.created_by_id === userId,
          likesCount: row.likes_count,
        }));

        if (pageNumber > 1) {
          setSeries((prev) => prev.concat(resultSeries));
        } else {
          setSeries(resultSeries);
        }

        page.current = pageNumber;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching series', error);
      } finally {
        setIsLoading(false);
      }
    },
    [args?.userCreatedOnly, args?.listAll, args?.search, userId],
  );

  const fetchMore = useCallback(() => {
    fetchSeries(page.current + 1).catch(() => void 0);
  }, [fetchSeries]);

  useEffect(() => {
    if (!args?.pauseFetch) {
      fetchSeries(1).catch(() => void 0);
    }
  }, [fetchSeries, args?.pauseFetch]);

  return {
    series,
    isLoading,
    hasMore,
    fetchMore,
  };
};
