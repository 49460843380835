import { useUpdateDiscoverySectionSeriesMutation } from '@maestro/graphql';
import { SectionSeries, Story } from '../sections.type';

export const useSaveSection = () => {
  const { mutate, isLoading: isSaving } =
    useUpdateDiscoverySectionSeriesMutation();

  const saveSection = async (section: SectionSeries, series?: Story[]) => {
    mutate({
      input: {
        id: section.id,
        name: section.name,
        series: series?.map((s) => s.id),
        onlyForKids: section.payload.onlyForKids,
      },
    });
  };

  return { saveSection, isSaving };
};
