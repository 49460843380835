import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  allowEmpty: boolean;
  value: string;
  onChange: (value: string) => void;
  options: string[];
};

export const EnumDropdown: React.FC<Props> = ({
  value,
  onChange,
  options,
  allowEmpty,
}) => {
  return (
    <Menu>
      <MenuDropdownButton>
        {value ?? <Placeholder>Select value</Placeholder>}
      </MenuDropdownButton>
      <MenuList>
        {allowEmpty && (
          <MenuItem onClick={() => onChange(undefined as never)}>
            <ValueContainer>
              <Empty>(Empty)</Empty>
            </ValueContainer>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option} onClick={() => onChange(option)}>
            <ValueContainer>
              <Name>{option}</Name>
            </ValueContainer>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Empty = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
