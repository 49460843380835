import { cleanEnv, str, num } from 'envalid';

export const env = cleanEnv(import.meta.env, {
  VITE_SUPABASE_URL: str(),
  VITE_SUPABASE_PUBLIC_KEY: str(),
  VITE_MAESTRO_API_URL: str(),
  VITE_ENIGMA_GQL_API: str(),
  VITE_LOGROCKET_APP_ID: str({ default: '' }),
  VITE_AMPLITUDE_API_KEY: str({ default: '' }),
  VITE_LAUNCH_DARKLY_CLIENT_ID: str({ default: '' }),
  VITE_SHARE_QR_CODE_LINK: str({ default: '' }),
  VITE_SHARE_DRAFT_APP_LINK: str({ default: '' }),
  VITE_BASE_GQL_URL: str({ default: '' }),
  VITE_REALTIME_CHANNEL_ENV_SEGMENT: str({ default: '' }),
  VITE_MAESTRO_VERSION: num({ default: 1 }),
  VITE_SENTRY_DSN: str({ default: '' }),
  VITE_DEPLOYMENT_ENVIRONMENT: str({ default: 'dev' }),
  VITE_STUDIO_FUNCTIONS_API_URL: str({ default: '' }),
});
